import { Button, Grid, Modal, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { gridItemStyles, gridStyles } from '../../shared/ComponentStyles';
import { blueGrey } from '@mui/material/colors';

export default function DeleteContactNumberModal({ deleteContactNumberModal, setDeleteContactNumberModal, deleteContactNumber, confirmDeleteContactNumber }) {
	return (
		<Modal open={deleteContactNumberModal}>
			<Box
				sx={style}
				style={{
					width: deleteContactNumber && deleteContactNumber.Number && deleteContactNumber.Number.toString().length + 20 + 'ch',
				}}
				component='form'
				onSubmit={confirmDeleteContactNumber}
			>
				<Box sx={{ marginTop: 4, textAlign: 'center' }}>
					<Typography sx={{ fontWeight: 'bold' }}>
						Are you sure you want to delete <br />
						the selected Contact Number?
					</Typography>
				</Box>
				<Box sx={{ marginTop: 4 }}>
					<Typography sx={{ fontWeight: 'bold', display: 'inline' }}>Type:</Typography>
					<Typography sx={{ display: 'inline', marginLeft: 2 }}>{deleteContactNumber && deleteContactNumber.Type && deleteContactNumber.Type.Name}</Typography>
					<br />
					<Typography sx={{ fontWeight: 'bold', display: 'inline' }}>Number:</Typography>
					<Typography sx={{ display: 'inline', marginLeft: 2 }}>{deleteContactNumber && deleteContactNumber.Number}</Typography>
				</Box>
				<Box
					sx={{
						marginTop: 4,
					}}
				>
					<Grid
						container
						sx={gridStyles}
					>
						<Grid
							sm={6}
							sx={gridItemStyles}
							item
						>
							<Button
								variant='contained'
								onClick={() => setDeleteContactNumberModal(false)}
								sx={{
									backgroundColor: blueGrey[500],

									'&:hover': { backgroundColor: blueGrey[600] },
								}}
							>
								Cancel
							</Button>
						</Grid>

						<Grid
							sm={6}
							sx={gridItemStyles}
							item
						>
							<Button
								variant='contained'
								onClick={confirmDeleteContactNumber}
								sx={{
									backgroundColor: blueGrey[500],
									'&:hover': { backgroundColor: blueGrey[600] },
								}}
							>
								Delete
							</Button>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</Modal>
	);
}

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
};
