import React, { useEffect } from 'react';
import {
	Box,
	TextField,
	MenuItem,
	Button,
	Modal,
	Backdrop,
	Stack,
	Fade,
} from '@mui/material';
import { blueGrey, orange } from '@mui/material/colors';
import { textFieldStyles } from './ComponentStyles';
import {
	changeEditingAccountParent,
	getAccountsAsync,
} from '../../redux/accountSlice';
import { useDispatch, useSelector } from 'react-redux';

export default function AccountParentModal({
	type,
	account,
	parentAccountModal,
	handleParentAccountModal,
}) {
	const dispatch = useDispatch();

	const accounts = useSelector((state) => {
		if (type === 'Buyer') {
			return state.accounts.buyers;
		}
		if (type === 'Producer') {
			return state.accounts.producers;
		}
		if (type === 'Other') {
			return state.accounts.others;
		}
		return null;
	});

	useEffect(() => {
		if (!accounts) {
			dispatch(getAccountsAsync(type));
		}
	});

	function updateParent(e) {
		const parent = accounts.find((account) => account._id === e.target.value);

		dispatch(
			changeEditingAccountParent({
				id: account._id,
				add: parent ? true : false,
				type: `${type.toLowerCase()}s`,
				account: {
					_id: account._id,
					Name: account.Name,
					AccountNumber: account.AccountNumber,
				},
				parent: {
					_id: parent ? parent._id : account.Parent._id,
					Name: parent ? parent.Name : account.Parent.Name,
					AccountNumber: parent
						? parent.AccountNumber
						: account.Parent.AccountNumber,
				},
			})
		);

		handleParentAccountModal();
	}
	return (
		<Modal
			open={parentAccountModal}
			closeAfterTransition
			slots={{ backdrop: Backdrop }}
		>
			<Fade
				in={parentAccountModal}
				timeout={300}
			>
				<Box
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						bgcolor: blueGrey[50],
						border: '2px solid #000',
						boxShadow: 24,
						p: 4,
						width: '500px',
					}}
				>
					<Box
						sx={{
							textAlign: 'center',
							fontSize: '1.25em',
							color: blueGrey[900],
						}}
					>
						<TextField
							key='Parent Account'
							select
							variant='standard'
							label='Parent Account'
							value={
								account.Parent && account.Parent._id
									? account.Parent._id
									: 'SELECT'
							}
							name='Parent'
							onChange={updateParent}
							sx={textFieldStyles}
						>
							<MenuItem
								key={'SELECT'}
								value={'SELECT'}
								disabled
							>
								SELECT
							</MenuItem>
							<MenuItem
								key={'REMOVE'}
								value={'REMOVE'}
							>
								REMOVE PARENT
							</MenuItem>
							{accounts &&
								accounts.map((acc) => (
									<MenuItem
										key={acc._id}
										value={acc._id}
										sx={{
											display: account._id !== acc._id ? 'block' : 'none',
										}}
									>
										{acc.Name}
									</MenuItem>
								))}
						</TextField>
						<Stack
							spacing={2}
							direction='row'
							sx={{ marginTop: '3em' }}
						>
							<Button
								variant='contained'
								onClick={handleParentAccountModal}
								sx={{
									flexGrow: 1,
									backgroundColor: orange[800],
									'&:hover': { backgroundColor: orange[900] },
								}}
							>
								Cancel
							</Button>
						</Stack>
					</Box>
				</Box>
			</Fade>
		</Modal>
	);
}
