import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import Account from './Account';
import { useDispatch, useSelector } from 'react-redux';
import { selectEditingAccount, addEditingAccount, changeEditingAccount } from '../../../redux/accountSlice';
import { selectUser } from '../../../redux/authSlice';
import { selectUsers } from '../../../redux/userSlice';
import Contact from './Contact';
import { blueGrey, orange } from '@mui/material/colors';
import { Fade } from '@mui/material';
import Submit from './Submit';

const steps = ['Enter Company Information', 'Enter Contact Information', `Create New Account `];

export default function NewAccountStepper({ id, type }) {
	const dispatch = useDispatch();
	const user = useSelector(selectUser);
	const users = useSelector(selectUsers);
	const newAccount = useSelector(selectEditingAccount(id));

	useEffect(() => {
		if (!newAccount) {
			dispatch(
				addEditingAccount({
					saving: false,
					saved: false,
					newId: null,
					Step: 0,
					_id: id,
					IsActive: true,
					Owner: user._id,
					Parent: undefined,
					Name: '',
					Type: type,
					Territory: '',
					RightsTree: [],
					Currency: '',
					Website: '',
					Phone: '',
					Contacts: [
						{
							_id: id,
							Saved: false,
							IsActive: true,
							Owner: user._id,
							Salutation: '',
							FirstName: '',
							LastName: '',
							Title: '',
							Territory: '',
							Department: [],
							ContactNumbers: [],
							Email: '',
							Address1: '',
							Address2: '',
							City: '',
							State: '',
							PostalCode: '',
							Country: '',
							Notes: '',
						},
					],
					Notes: '',
				})
			);
		}
		//eslint-disable-next-line
	}, [newAccount]);

	const handleNext = () => {
		dispatch(
			changeEditingAccount({
				id: id,
				name: 'Step',
				value: newAccount.Step + 1,
			})
		);
	};

	const handleBack = () => {
		dispatch(
			changeEditingAccount({
				id: id,
				name: 'Step',
				value: newAccount.Step - 1,
			})
		);
	};

	return (
		<Box
			sx={{
				width: '100%',
				height: '87vh',
				overflow: 'auto',
				backgroundColor: 'inherit',
				padding: 8,
				borderRadius: 2,
			}}
		>
			<Stepper activeStep={newAccount && newAccount.Step}>
				{steps.map((label, index) => {
					const stepProps = { color: orange[800] };
					const labelProps = { color: orange[800] };
					return (
						<Step
							key={label}
							{...stepProps}
							sx={{
								'& .MuiStepLabel-root .Mui-completed': {
									color: 'secondary.main', // circle color (COMPLETED)
								},
								'& .MuiStepLabel-root .Mui-active': {
									color: 'secondary.dark', // circle color (ACTIVE)
								},
								'& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
									fill: blueGrey[50], // circle's number (ACTIVE)
								},
							}}
						>
							<StepLabel {...labelProps}>{label}</StepLabel>
						</Step>
					);
				})}
			</Stepper>
			<Typography sx={{ mt: 2, mb: 4, color: blueGrey[800] }}></Typography>
			{newAccount && (
				<>
					{newAccount.Step === 0 && (
						<Fade
							in={true}
							timeout={{ enter: 800, exit: 800 }}
						>
							<div>
								<Account
									id={id}
									type={type}
									newAccount={newAccount}
									user={user}
									users={users}
									handleNext={handleNext}
								/>
							</div>
						</Fade>
					)}
					{newAccount.Step === 1 && (
						<Fade
							in={true}
							timeout={{ enter: 800, exit: 800 }}
						>
							<div>
								<Contact
									id={id}
									type={type}
									newAccount={newAccount}
									user={user}
									users={users}
									handleNext={handleNext}
									handleBack={handleBack}
								/>
							</div>
						</Fade>
					)}
					{newAccount.Step === 2 && (
						<Fade
							in={true}
							timeout={{ enter: 800, exit: 800 }}
						>
							<div>
								<Submit
									dispatch={dispatch}
									newAccount={newAccount}
									handleBack={handleBack}
								/>
							</div>
						</Fade>
					)}
				</>
			)}
			{/* <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
				<Button
					//disabled={activeStep === 0}
					onClick={handleBack}
					sx={{
						mr: 1,
						color: blueGrey[50],
						backgroundColor: orange[800],
						'&:hover': {
							backgroundColor: orange[900],
						},
						visibility: `${newAccount.Step === 0 ? 'hidden' : 'bisible'}`,
					}}
				>
					Back
				</Button>
				<Box sx={{ flex: '1 1 auto' }} />
				<Button
					type='submit'
					sx={{
						color: blueGrey[50],
						backgroundColor: orange[800],
						'&:hover': {
							backgroundColor: orange[900],
						},
					}}
				>
					Next
				</Button>
			</Box> */}
		</Box>
	);
}
