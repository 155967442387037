import { Button, FormControl, Grid, Input, InputLabel, MenuItem, Modal, Select, TextField } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { gridItemStyles, gridStyles } from '../ComponentStyles';
import { MuiTelInput } from 'mui-tel-input';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { blueGrey } from '@mui/material/colors';
export default function CreateContactNumberModal({
	createContactNumberModal,
	addNewContactNumber,
	newContactNumber,
	setNewContactNumber,
	contactNumberTypes,
	handleCreateContactNumberModal,
}) {
	return (
		<Modal open={createContactNumberModal}>
			<Box sx={style}>
				{newContactNumber && (
					<Box sx={{ textAlign: 'center' }}>
						<FormControl sx={textFieldStyles}>
							<InputLabel sx={{ left: -14 }}>Contact Number Type:</InputLabel>
							<Select
								required
								name='ContactNumberType'
								value={newContactNumber && newContactNumber.Type}
								input={<Input label='Contact Number Type'>Contact Number Type</Input>}
								MenuProps={{
									PaperProps: {
										style: {
											width: '20%',
											maxWidth: '20%',
										},
									},
								}}
								onChange={(e) =>
									setNewContactNumber({
										...newContactNumber,
										Type: e.target.value,
									})
								}
								sx={textFieldStyles}
							>
								<MenuItem
									disabled
									value={'Choose Type'}
								>
									Choose Type
								</MenuItem>
								{contactNumberTypes &&
									contactNumberTypes.map((contactNumberType) => (
										<MenuItem
											key={contactNumberType._id}
											value={contactNumberType}
										>
											{contactNumberType.Name}
										</MenuItem>
									))}
							</Select>
						</FormControl>
						{newContactNumber.Type !== 'Choose Type' && (
							<>
								{contactNumberTypes.find((x) => x.Name === newContactNumber.Type.Name).ValidNumber ? (
									<MuiTelInput
										required
										key={newContactNumber.id}
										type='phone'
										variant='standard'
										label={`${newContactNumber.Type.Name} Number:`}
										value={newContactNumber.Number}
										name={newContactNumber.id}
										onChange={(e) =>
											setNewContactNumber({
												...newContactNumber,
												Number: e,
											})
										}
										forceCallingCode={true}
										error={!isValidPhoneNumber}
										sx={textFieldStyles}
									/>
								) : (
									<TextField
										required
										key={newContactNumber.id}
										variant='standard'
										type='text'
										label={`${newContactNumber.Type.Name} Number:`}
										value={newContactNumber.Number}
										name={newContactNumber.id}
										onChange={(e) =>
											setNewContactNumber({
												...newContactNumber,
												Number: e.target.value,
											})
										}
										sx={textFieldStyles}
									/>
								)}
							</>
						)}
					</Box>
				)}
				<Box sx={{ marginTop: 8 }}>
					<Grid
						container
						sx={gridStyles}
					>
						<Grid
							sm={6}
							sx={gridItemStyles}
							item
						>
							<Button
								variant='contained'
								onClick={() => handleCreateContactNumberModal()}
								sx={{
									backgroundColor: blueGrey[500],

									'&:hover': { backgroundColor: blueGrey[600] },
								}}
							>
								Cancel
							</Button>
						</Grid>

						<Grid
							sm={6}
							sx={gridItemStyles}
							item
						>
							<Button
								onClick={addNewContactNumber}
								disabled={
									newContactNumber &&
									(newContactNumber.Type === 'Choose Type' ||
										newContactNumber.Number === '' ||
										(newContactNumber.Type.ValidNumber && !isValidPhoneNumber(newContactNumber.Number)))
								}
								variant='contained'
								sx={{
									backgroundColor: blueGrey[500],
									'&:hover': { backgroundColor: blueGrey[600] },
								}}
							>
								Submit
							</Button>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</Modal>
	);
}

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
};

const textFieldStyles = {
	minWidth: '80%',
	textAlign: 'left',
	color: blueGrey[900],
	marginTop: '1em',
	marginBottom: '1em',

	'& .MuiInput-root': {
		color: blueGrey[900],
	},
	'& .MuiInputLabel-root': {
		color: blueGrey[900],
	},
	'& .MuiInput-root:after': {
		borderColor: blueGrey[900],
	},
};
