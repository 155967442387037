import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getContactReport, getContactReportAsync, selectContactReport } from '../../redux/reportSlice';
import { Button, Grid } from '@mui/material';
import { blueGrey } from '@mui/material/colors';

function ContactReport() {
	const dispatch = useDispatch();
	const contactReport = useSelector(selectContactReport);

	function handleButtonClick(e) {
		dispatch(getContactReportAsync());
	}

	const downloadFile = ({ data, fileName, fileType }) => {
		const blob = new Blob([data], { type: fileType });
		const a = document.createElement('a');
		a.download = fileName;
		a.href = window.URL.createObjectURL(blob);
		const clickEvt = new MouseEvent('click', {
			view: window,
			bubbles: true,
			cancelable: true,
		});
		a.dispatchEvent(clickEvt);
		a.remove();
	};

	const exportToCsv = (e) => {
		e.preventDefault();
		// Headers
		let headers = ['Account,Territory,Name,Title,Department,Email'];

		// Data: Iterate through ScreenerReports
		let report = [];

		contactReport.forEach((account) => {
			account.Contacts.forEach((contact) => {
				if (contact.IsActive) {
					let reportItem = [
						account.Name.replaceAll(',', ' '),
						account.Territory.replaceAll(',', ' '),
						contact.FirstName.replaceAll(',', ' ') + ' ' + contact.LastName.replaceAll(',', ' '),
						contact.Title.replaceAll(',', ' '),
						contact.Department.toString().replaceAll(',', ' '),
						contact.Email.replaceAll(',', ' '),
					];
					report.push(reportItem.join(','));
				}
			});
		});

		const dateObj = new Date();
		const month = dateObj.getUTCMonth() + 1; // months from 1-12
		const day = dateObj.getUTCDate();
		const year = dateObj.getUTCFullYear();

		let newDate = year + '/' + month + '/' + day;

		// Using template literals:
		newDate = `${year}/${month}/${day}`;

		// Using padded values, so that 2023/1/7 becomes 2023/01/07
		const pMonth = month.toString().padStart(2, '0');
		const pDay = day.toString().padStart(2, '0');
		const newPaddedDate = `${year}-${pMonth}-${pDay}`;

		downloadFile({
			data: [...headers, ...report].join('\n'),
			fileName: `ContactsReport.${newPaddedDate}.csv`,
			fileType: 'text/csv',
		});
	};

	useEffect(() => {
		return () => {
			if (contactReport) {
				dispatch(getContactReport(null));
			}
		};

		//eslint-disable-next-line
	}, []);

	return (
		<Grid container>
			<Grid
				item
				sm={12}
				sx={{ fontSize: 30, marginTop: 4, marginBottom: 12 }}
			>
				Contacts Report
			</Grid>
			<Grid
				item
				sm={12}
				sx={{ marginTop: 12 }}
			>
				<Button
					onClick={handleButtonClick}
					sx={{
						color: blueGrey[50],
						backgroundColor: blueGrey[700],
						border: `2px solid ${blueGrey[800]}`,
						paddingTop: 2,
						paddingBottom: 2,
						paddingLeft: 8,
						paddingRight: 8,
						'&:hover': {
							backgroundColor: blueGrey[900],
						},
					}}
				>
					Get Report
				</Button>
			</Grid>
			{contactReport && (
				<>
					<Grid
						item
						sm={12}
						sx={{ fontSize: 20, marginTop: 12 }}
					>
						{/* {contactReport.length} Items Found */}
						Report Ready
					</Grid>
					<Grid
						item
						sm={12}
						sx={{ marginTop: 12 }}
					>
						<Button
							onClick={exportToCsv}
							sx={{
								color: blueGrey[50],
								backgroundColor: blueGrey[700],
								border: `2px solid ${blueGrey[800]}`,
								paddingTop: 2,
								paddingBottom: 2,
								paddingLeft: 8,
								paddingRight: 8,
								'&:hover': {
									backgroundColor: blueGrey[900],
								},
							}}
						>
							Download Spreadsheet
						</Button>
					</Grid>
				</>
			)}
		</Grid>
	);
}

export default ContactReport;
