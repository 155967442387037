import { createSlice } from '@reduxjs/toolkit';
import api from '../utils/api';
import { createAlertAsync, handleErrorAlertAsync } from './alertsSlice';
import { verifyAuthAsync } from './authSlice';

const initialState = {
	contactnumbertypes: null,
	editing: null,
};

export const contactNumberTypeSlice = createSlice({
	name: 'contactnumbertype',
	initialState: initialState,
	reducers: {
		createContactNumberType: (state, action) => {
			state.contactnumbertypes.push(action.payload);

			state.contactnumbertypes = state.contactnumbertypes.sort((a, b) => {
				const typeA = a.Name.toUpperCase();
				const typeB = b.Name.toUpperCase();
				if (typeA < typeB) {
					return -1;
				}
				if (typeA > typeB) {
					return 1;
				}

				return 0;
			});

			state.contactnumbertypes = state.contactnumbertypes.sort((a, b) => {
				const typeA = a.IsActive;
				const typeB = b.IsActive;
				if (typeA < typeB) {
					return -1;
				}
				if (typeB > typeA) {
					return 1;
				}

				return 0;
			});
		},
		getContactNumberType: (state, action) => {
			state.editing = action.payload;
		},
		getContactNumberTypes: (state, action) => {
			state.contactnumbertypes = action.payload;

			state.contactnumbertypes = state.contactnumbertypes.sort((a, b) => {
				const typeA = a.Name.toUpperCase();
				const typeB = b.Name.toUpperCase();
				if (typeA < typeB) {
					return -1;
				}
				if (typeA > typeB) {
					return 1;
				}

				return 0;
			});

			state.contactnumbertypes = state.contactnumbertypes.sort((a, b) => {
				const typeA = a.IsActive;
				const typeB = b.IsActive;
				if (typeA > typeB) {
					return -1;
				}
				if (typeB < typeA) {
					return 1;
				}

				return 0;
			});
		},
		updateContactNumberType: (state, action) => {
			const contactnumbertypeIndex = state.contactnumbertypes.findIndex(
				(dept) => dept._id === action.payload._id
			);

			state.contactnumbertypes[contactnumbertypeIndex] = action.payload;

			state.contactnumbertypes = state.contactnumbertypes.sort((a, b) => {
				const typeA = a.Name.toUpperCase();
				const typeB = b.Name.toUpperCase();
				if (typeA < typeB) {
					return -1;
				}
				if (typeA > typeB) {
					return 1;
				}

				return 0;
			});

			state.contactnumbertypes = state.contactnumbertypes.sort((a, b) => {
				const typeA = a.IsActive;
				const typeB = b.IsActive;
				if (typeA > typeB) {
					return -1;
				}
				if (typeB < typeA) {
					return 1;
				}

				return 0;
			});

			state.editing = action.payload;
		},
		removeContactNumberTypes: (state, action) => {
			state.contactnumbertypes = null;
		},
		setEditingContactNumberType: (state, action) => {
			let value = action.payload.value;
			if (action.payload.name === 'ValidNumber') {
				value = action.payload.checked;
			}

			state.editing = {
				...state.editing,
				[action.payload.name]: value,
			};
		},
		removeContactNumberType: (state, action) => {
			state.editing = null;
		},
		resetContactNumberTypes: () => initialState,
	},
});

export const {
	createContactNumberType,
	getContactNumberType,
	getContactNumberTypes,
	updateContactNumberType,
	removeContactNumberTypes,
	setEditingContactNumberType,
	removeContactNumberType,
	resetContactNumberTypes,
} = contactNumberTypeSlice.actions;

// Selectors
export const selectContactNumberTypes = (state) =>
	state.contactnumbertype.contactnumbertypes;

export const selectContactNumberType = () => (state) => {
	if (
		state.contactnumbertype.contactnumbertypes &&
		state.contactnumbertype.editing
	) {
		return state.contactnumbertype.contactnumbertypes.find(
			(contactnumbertype) =>
				contactnumbertype._id === state.contactnumbertype.editing._id
		);
	} else {
		return null;
	}
};

export const selectEditingContactNumberType = () => (state) => {
	return state.contactnumbertype.editing;
};

// Create Contact Number Type Async
export const createContactNumberTypeAsync =
	(name, validNumber) => async (dispatch) => {
		await api
			.post(
				`/admin/contactnumbertypes`,
				{ Name: name, ValidNumber: validNumber },
				{ withCredentials: true }
			)
			.then((res) => {
				dispatch(createContactNumberType(res.data));
			})
			.catch((error) => {
				dispatch(handleErrorAlertAsync(error));
			});
	};

// Get Contact Number Types Async
export const getContactNumberTypesAsync = () => async (dispatch) => {
	await api
		.get(`/admin/contactnumbertypes`, { withCredentials: true })
		.then((res) => {
			dispatch(getContactNumberTypes(res.data));
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
			dispatch(verifyAuthAsync(error.response.data.message));
		});
};

// Get Contact Number Type Async
export const getContactNumberTypeAsync = (id) => async (dispatch) => {
	await api
		.get(`/admin/contactnumbertypes/${id}`, { withCredentials: true })
		.then((res) => {
			dispatch(getContactNumberType(res.data));
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
			dispatch(verifyAuthAsync(error.response.data.message));
		});
};

// Update Contact Number Type Async
export const updateContactNumberTypeAsync =
	(contactnumbertype) => async (dispatch) => {
		await api
			.put(
				`/admin/contactnumbertypes/${contactnumbertype._id}`,
				contactnumbertype,
				{
					withCredentials: true,
				}
			)
			.then((res) => {
				dispatch(updateContactNumberType(res.data));
				dispatch(
					createAlertAsync({
						message: 'Contact Number Type Updated Successfully',
						severity: 'success',
						autoDismiss: true,
						timeout: 6000,
					})
				);
			})
			.catch((error) => {
				dispatch(handleErrorAlertAsync(error));
			});
	};

export default contactNumberTypeSlice.reducer;
