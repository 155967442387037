import React, { useEffect, useState } from 'react';
import { blueGrey } from '@mui/material/colors';
import { Box, Grid, Tooltip, IconButton } from '@mui/material';
import { updateAccountContactAsync } from '../../../../redux/accountSlice';
import { useDispatch } from 'react-redux';
import { AddCircle } from '@mui/icons-material';
import ContactModal from '../../contact/ContactModal';
import ContactAccordion from './contactsTab/ContactAccordion';
import { isPossiblePhoneNumber, isValidPhoneNumber, validatePhoneNumberLength } from 'libphonenumber-js';
import { createAlertAsync } from '../../../../redux/alertsSlice';

export default function ContactsTab({ originalAccount, editingAccount, users }) {
	const dispatch = useDispatch();
	const [saving, setSaving] = useState(false);
	const [expanded, setExpanded] = useState(null);
	const [newContactModal, setNewContactModal] = useState(false);

	function newContactModalOpen() {
		setNewContactModal(true);
	}

	function newContactModalClose() {
		setNewContactModal(false);
	}

	function handleAccordionOpen(contactId) {
		if (expanded === contactId) {
			setExpanded(null);
		} else {
			setExpanded(contactId);
		}
	}

	function submit(e, contact) {
		e.preventDefault();

		let phoneNumbersValid = true;

		if (contact.ContactNumbers.length === 0) {
			phoneNumbersValid = false;
			dispatch(
				createAlertAsync({
					message: 'Must Add At Least One Contact Number',
					severity: 'error',
					autoDismiss: true,
					timeout: 5000,
				})
			);
		}

		contact.ContactNumbers.forEach((contactNumber) => {
			if (contactNumber.Type.ValidNumber) {
				if (!isPossiblePhoneNumber(contactNumber.Number) || !isValidPhoneNumber(contactNumber.Number) || validatePhoneNumberLength(contactNumber.Number) === 'TOO_SHORT' || validatePhoneNumberLength(contactNumber.Number) === 'TOO_LONG') {
					phoneNumbersValid = false;

					dispatch(
						createAlertAsync({
							message: `${contactNumber.Number} Is Not A Valid Number`,
							severity: 'error',
							autoDismiss: true,
							timeout: 5000,
						})
					);
				}
			}
		});

		if (phoneNumbersValid) {
			setSaving(true);
			dispatch(updateAccountContactAsync(contact));
		}
	}

	useEffect(() => {
		setSaving(false);
	}, [originalAccount.Contacts]);

	return (
		<Box
			sx={{
				margin: '2em 1em',
			}}
		>
			<Grid
				container
				sx={{
					backgroundColor: blueGrey[50],
					marginBottom: 2,
					borderTopLeftRadius: 10,
					borderTopRightRadius: 10,
					color: blueGrey[900],
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<Grid
					item
					sx={{ flexGrow: 1, fontWeight: 'bold', color: blueGrey[700] }}
				></Grid>
				<Grid
					item
					sx={{ flexGrow: 0 }}
				>
					<Tooltip
						title='Add Contact'
						arrow={true}
					>
						<IconButton onClick={newContactModalOpen}>
							<AddCircle
								sx={{
									color: blueGrey[600],
									backgroundColor: 'transparent',
									borderBottomRadius: 10,
									borderTopRadius: 10,
									fontSize: '1.5em',
								}}
							/>
						</IconButton>
					</Tooltip>
				</Grid>
			</Grid>
			<Grid
				container
				sx={{
					backgroundColor: blueGrey[50],
					color: blueGrey[800],
					padding: '1em 0',
					boxShadow: `0px 0px 2px .5px ${blueGrey[600]}`,
				}}
			>
				<Grid
					sm={3}
					item
				>
					Name
				</Grid>
				<Grid
					sm={2}
					item
				>
					Title
				</Grid>
				<Grid
					sm={2}
					item
				>
					Department
				</Grid>
				<Grid
					sm={3}
					item
				>
					Email
				</Grid>
				<Grid
					sm={2}
					item
				>
					Status
				</Grid>
			</Grid>
			{editingAccount.Contacts.length > 0 &&
				[...editingAccount.Contacts]
					.sort((a, b) => (a.IsActive === b.IsActive ? 0 : a.IsActive ? -1 : 1))
					.map((contact, index) => (
						<Box key={contact._id}>
							<ContactAccordion
								originalAccount={originalAccount}
								editingAccount={editingAccount}
								contact={contact}
								index={index}
								submit={submit}
								saving={saving}
								setSaving={setSaving}
								expanded={expanded}
								setExpanded={setExpanded}
								handleAccordionOpen={handleAccordionOpen}
							/>
						</Box>
					))}
			{newContactModal && (
				<ContactModal
					originalAccount={originalAccount}
					editingAccount={editingAccount}
					newContactModal={newContactModal}
					newContactModalClose={newContactModalClose}
					users={users}
				/>
			)}
		</Box>
	);
}
