import React, { useState, useEffect } from 'react';
import { Box, Grid, IconButton, Button, Modal, Backdrop, CircularProgress } from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { addAccountContact, addAccountContactAsync, deleteAccountContact, selectEditingContact } from '../../../redux/accountSlice';
import { selectUser } from '../../../redux/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { gridStyles, gridItemStyles } from '../ComponentStyles';
import SaveIcon from '@mui/icons-material/Save';
import { isPossiblePhoneNumber, isValidPhoneNumber, validatePhoneNumberLength } from 'libphonenumber-js';
import { createAlertAsync } from '../../../redux/alertsSlice';
import ContactForm from './ContactForm';

export default function ContactModal({ originalAccount, editingAccount, newContactModal, newContactModalClose }) {
	const dispatch = useDispatch();
	const currentUser = useSelector(selectUser);
	const [saving, setSaving] = useState(false);
	const [id] = useState(`new-${crypto.randomUUID()}`);
	const newContact = useSelector(selectEditingContact(editingAccount._id, id));

	useEffect(() => {
		if (!newContact) {
			dispatch(
				addAccountContact({
					_id: id,
					Saved: false,
					IsActive: true,
					Account: originalAccount._id,
					Owner: currentUser._id,
					Salutation: '',
					FirstName: '',
					LastName: '',
					Title: '',
					Territory: '',
					Department: [],
					ContactNumbers: [],
					Email: '',
					Address1: '',
					Address2: '',
					City: '',
					State: '',
					PostalCode: '',
					Country: '',
					Notes: '',
				})
			);
		}

		return () => {
			dispatch(deleteAccountContact({ accountId: editingAccount._id, contactId: id }));
		};

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (newContact && newContact.Saved) {
			setSaving(false);
			newContactModalClose();
			dispatch(deleteAccountContact({ accountId: editingAccount._id, contactId: id }));
		}

		// eslint-disable-next-line
	}, [newContact]);

	function addContact(e) {
		e.preventDefault();

		let phoneNumbersValid = true;

		if (newContact.ContactNumbers.length === 0) {
			phoneNumbersValid = false;
			dispatch(
				createAlertAsync({
					message: 'Must Add At Least One Contact Number',
					severity: 'error',
					autoDismiss: true,
					timeout: 5000,
				})
			);
		}

		newContact.ContactNumbers.forEach((contactNumber) => {
			console.log(contactNumber.Number);
			if (contactNumber.Type.ValidNumber) {
				if (!isPossiblePhoneNumber(contactNumber.Number) || !isValidPhoneNumber(contactNumber.Number) || validatePhoneNumberLength(contactNumber.Number) === 'TOO_SHORT' || validatePhoneNumberLength(contactNumber.Number) === 'TOO_LONG') {
					phoneNumbersValid = false;

					dispatch(
						createAlertAsync({
							message: `${contactNumber.Number} Is Not A Valid Number`,
							severity: 'error',
							autoDismiss: true,
							timeout: 5000,
						})
					);
				}
			}
		});

		if (phoneNumbersValid) {
			setSaving(true);
			dispatch(addAccountContactAsync(newContact, originalAccount.Type));
		}
	}

	function cancel() {
		newContactModalClose();
		dispatch(deleteAccountContact({ accountId: editingAccount._id, contactId: id }));
	}

	return (
		<>
			<Modal
				open={newContactModal}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 1000,
				}}
			>
				<Box
					component='form'
					onSubmit={addContact}
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						bgcolor: blueGrey[50],
						border: `2px solid ${blueGrey[800]}`,
						boxShadow: 24,
						borderRadius: 4,
						width: '90%',
						height: '90%',
						minHeight: '90%',
						overflowY: 'hidden',
					}}
				>
					<Box sx={{ margin: 0, marginTop: 0, width: '100%', padding: 4, height: '80vh', overflowY: 'auto', overFlowX: 'hidden' }}>
						<Grid
							container
							sx={gridStyles}
						>
							<Grid
								sm={10}
								sx={{
									textAlign: 'left',
									paddingLeft: 6,
									fontWeight: 'bold',
									color: blueGrey[600],
									fontSize: '1.5em',
									borderRadius: 4,
								}}
								item
							>
								New Contact
							</Grid>
							<Grid
								sm={2}
								sx={gridItemStyles}
								item
							>
								{saving ? (
									<CircularProgress color='warning' />
								) : (
									<IconButton
										type='submit'
										style={{ border: 'none' }}
									>
										<SaveIcon
											style={{
												color: blueGrey[800],
												fontSize: '1.5em',
												cursor: 'pointer',
												'&:hover': { color: blueGrey[900] },
											}}
										/>
									</IconButton>
								)}
							</Grid>
						</Grid>
						{newContact && (
							<ContactForm
								originalAccount={originalAccount}
								editingAccount={editingAccount}
								contact={newContact}
								textFieldStyles={textFieldStyles}
								variant={'outlined'}
							/>
						)}

						<Box sx={{ marginTop: 8 }}>
							<Grid
								container
								sx={gridStyles}
							>
								<Grid
									sm={6}
									sx={gridItemStyles}
									item
								>
									<Button
										variant='contained'
										onClick={cancel}
										sx={{
											backgroundColor: blueGrey[500],
											width: 200,
											'&:hover': { backgroundColor: blueGrey[600] },
										}}
									>
										Cancel
									</Button>
								</Grid>
								<Grid
									sm={6}
									sx={gridItemStyles}
									item
								>
									<Button
										variant='contained'
										type='submit'
										sx={{
											backgroundColor: blueGrey[500],
											width: 200,
											'&:hover': { backgroundColor: blueGrey[600] },
										}}
									>
										Save
									</Button>
								</Grid>
							</Grid>
						</Box>
					</Box>
				</Box>
			</Modal>
		</>
	);
}

const textFieldStyles = {
	minWidth: '80%',
	textAlign: 'left',
	color: blueGrey[900],
	marginTop: '1em',
	marginBottom: '1em',
	'& .MuiInput-input': {
		color: blueGrey[900],
		backgroundColor: blueGrey[50],
	},
	'& .MuiInput-root': {
		color: blueGrey[900],
	},
	'& .MuiInputLabel-root': {
		color: blueGrey[900],
	},
	'& .MuiInput-root:after': {
		borderColor: blueGrey[900],
	},
};
