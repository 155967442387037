import React, { useEffect } from 'react';
import { blueGrey } from '@mui/material/colors';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/system';
import ScreenerReports from '../components/reports/ScreenerReports';
import ContactReport from '../components/reports/ContactReport';

function Reports({ setParams, location }) {
	let params = useParams();

	useEffect(() => {
		setParams(params);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	function renderContent() {
		if (params.sub === 'contacts_report') {
			return <ContactReport />;
		}

		if (params.sub === 'screener_reports') {
			return <ScreenerReports />;
		}
	}

	return (
		<Box
			sx={{
				padding: '2em',
				paddingTop: '1em',
				textAlign: 'center',
				backgroundColor: blueGrey[500],
				color: 'white',
				height: '90vh',
				overflowY: 'auto',
				borderRadius: '8px',
				boxShadow: `0px 5px 10px 5px${blueGrey[800]}`,
			}}
		>
			{renderContent()}
		</Box>
	);
}

export default Reports;
