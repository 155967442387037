import React, { useEffect } from 'react';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { blueGrey, orange } from '@mui/material/colors';
import { createNewAccountAsync, changeEditingAccount } from '../../../redux/accountSlice';
import { useNavigate } from 'react-router-dom';
import { closeTab } from '../../../redux/tabsSlice';
import { removeAccount } from '../../../redux/accountSlice';

export default function Submit({ newAccount, dispatch, handleBack }) {
	const navigate = useNavigate();

	function saveNewAccount() {
		dispatch(
			changeEditingAccount({
				id: newAccount._id,
				name: 'saving',
				value: true,
			})
		);
		dispatch(createNewAccountAsync(newAccount));
	}

	useEffect(() => {
		if (!newAccount.saving && newAccount.saved && newAccount.newId) {
			dispatch(removeAccount(newAccount._id));
			switch (newAccount.Type) {
				case 'Buyer':
					navigate(`/sales/buyers/${newAccount.newId}`);
					break;
				case 'Producer':
					navigate(`/acquisitions/producers/${newAccount.newId}`);
					break;
				case 'Other':
					navigate(`/finance/others/${newAccount.newId}`);
					break;
				default:
					break;
			}

			dispatch(closeTab(`new-${newAccount.Type.toLowerCase()}`));
		}
		// eslint-disable-next-line
	}, [newAccount, dispatch]);

	return (
		<Box>
			{newAccount.saving ? <CircularProgress color='warning' /> : <Typography>{newAccount.Name}</Typography>}

			<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
				<Button
					disabled={newAccount.saving}
					onClick={handleBack}
					sx={{
						mr: 1,
						color: blueGrey[50],
						backgroundColor: orange[800],
						'&:hover': {
							backgroundColor: orange[900],
						},
					}}
				>
					Back
				</Button>
				<Box sx={{ flex: '1 1 auto' }} />
				<Button
					//disabled={newAccount.saving}
					onClick={saveNewAccount}
					sx={{
						color: blueGrey[50],
						backgroundColor: orange[800],
						'&:hover': {
							backgroundColor: orange[900],
						},
					}}
				>
					Save
				</Button>
			</Box>
		</Box>
	);
}
