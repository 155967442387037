import React from 'react';
import { TextField } from '@mui/material';

export default function ContactNumberInput({ contactNumber, handleContactNumberChange, contact, disabled, variant, textFieldStyles }) {
	return (
		<TextField
			key={contactNumber._id}
			required
			disabled={disabled}
			variant={variant}
			type='text'
			label={contactNumber.Type.Name}
			value={contactNumber.Number}
			name={contactNumber._id}
			onChange={(e) => handleContactNumberChange(e.target.value, contact._id, contactNumber._id, contactNumber.Type)}
			sx={textFieldStyles}
			style={{
				marginTop: 0,
				marginBottom: 0,
				left: 4,
			}}
		/>
	);
}
