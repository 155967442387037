import { Button, Checkbox, Divider, FormControl, Grid, Input, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { gridItemStyles, gridStyles } from '../ComponentStyles';
import { AddCircle, Cancel, DeleteForever } from '@mui/icons-material';
import { blueGrey, orange, red } from '@mui/material/colors';
import ContactNumberInput from './ContactNumberInput';
import ContactNumberPhoneInput from './ContactNumberPhoneInput';
import CreatedModified from '../CreatedModified';
import CreateContactNumberModal from './CreateContactNumberModal';
import DeleteContactNumberModal from './DeleteContactNumberModal';
import { selectDepartments } from '../../../redux/departmentSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getContactNumberTypesAsync, selectContactNumberTypes } from '../../../redux/contactNumberTypeSlice';
import { selectUsers } from '../../../redux/userSlice';
import { addEditingAccountContactNumber, cancelDeleteEditingAccountContactNumber, changeEditingAccountContact, deleteEditingAccountContactNumber, updateEditingAccountContactNumber } from '../../../redux/accountSlice';
import { createAlertAsync } from '../../../redux/alertsSlice';
import { matchIsValidTel } from 'mui-tel-input';

export default function ContactForm({ originalAccount, editingAccount, contact, submit, saving, index, textFieldStyles, variant }) {
	const dispatch = useDispatch();
	const contactNumberTypes = useSelector(selectContactNumberTypes);
	const departments = useSelector(selectDepartments);
	const users = useSelector(selectUsers);
	const [createContactNumberModal, setCreateContactNumberModal] = useState(false);
	const [newContactNumber, setNewContactNumber] = useState(null);
	const [deleteContactNumberModal, setDeleteContactNumberModal] = useState(false);
	const [deleteContactNumber, setDeleteContactNumber] = useState(null);
	const [required, setRequired] = useState(true);

	useEffect(() => {
		dispatch(getContactNumberTypesAsync());

		// eslint-disable-next-line
	}, [editingAccount.Contacts]);

	useEffect(() => {
		setRequired(contact.ContactNumbers.length <= 0 || contact.ContactNumbers.filter((contactNumber) => !Object.hasOwn(contactNumber, 'ToDelete')).length <= 0);
		// eslint-disable-next-line
	}, [contact.ContactNumbers, required]);

	const handleContactChange = (e, contactId) => {
		dispatch(
			changeEditingAccountContact({
				id: editingAccount._id,
				contactId: contactId,
				name: e.target.name,
				value: e.target.value,
			})
		);
	};

	function handleDepartmentChange(e, contactId) {
		let newValue = typeof value === 'string' ? e.target.value.split(',') : e.target.value;

		let correctedValue = newValue.filter((value) => value !== '');
		dispatch(
			changeEditingAccountContact({
				id: editingAccount._id,
				contactId: contactId,
				name: e.target.name,
				value: correctedValue,
			})
		);
	}

	function handleCreateContactNumberModal() {
		if (!createContactNumberModal) {
			setNewContactNumber({
				_id: `new-${crypto.randomUUID()}`,
				Type: 'Choose Type',
				Number: '',
			});
			setCreateContactNumberModal(true);
		} else {
			setNewContactNumber(null);
			setCreateContactNumberModal(false);
		}
	}

	const handleContactNumberChange = (e, contactId, contactNumberId, contactNumberType) => {
		console.log(e);
		dispatch(
			updateEditingAccountContactNumber({
				id: editingAccount._id,
				contactId: contactId,
				contactNumberId: contactNumberId,
				contactNumberType: contactNumberType,
				value: e,
			})
		);
	};

	function addNewContactNumber(e) {
		e.preventDefault();

		//Check for errors
		if (newContactNumber && newContactNumber.Type === 'Choose Type') {
			dispatch(
				createAlertAsync({
					message: 'Contact Number Type Not Selected',
					severity: 'error',
					autoDismiss: true,
				})
			);
		} else if (newContactNumber && (newContactNumber.Type.Name === 'Phone' || newContactNumber.Type.Name === 'Mobile Phone') && !matchIsValidTel(newContactNumber.Number)) {
			dispatch(
				createAlertAsync({
					message: 'Contact Number Not Valid',
					severity: 'error',
					autoDismiss: true,
				})
			);
		} else {
			dispatch(
				addEditingAccountContactNumber({
					accountId: editingAccount._id,
					contactId: contact._id,
					contactNumber: newContactNumber,
				})
			);
		}
		setNewContactNumber(null);
		handleCreateContactNumberModal();
	}

	function handleDeleteContactNumberModal(contactNumber) {
		if (contactNumber._id.includes('new')) {
			dispatch(
				deleteEditingAccountContactNumber({
					accountId: editingAccount._id,
					contactId: contact._id,
					contactNumberId: contactNumber._id,
				})
			);
		} else {
			setDeleteContactNumber(contactNumber);
			setDeleteContactNumberModal(true);
		}
	}

	function handleCancelDelete(contactNumber) {
		dispatch(
			cancelDeleteEditingAccountContactNumber({
				accountId: editingAccount._id,
				contactId: contact._id,
				contactNumberId: contactNumber._id,
			})
		);
	}

	function confirmDeleteContactNumber(e) {
		e.preventDefault();
		dispatch(
			deleteEditingAccountContactNumber({
				accountId: editingAccount._id,
				contactId: contact._id,
				contactNumberId: deleteContactNumber._id,
			})
		);
		setDeleteContactNumber(null);
		setDeleteContactNumberModal(false);
	}

	return (
		<>
			{/* Form Elements*/}
			<Grid
				container
				sx={gridStyles}
			>
				<Grid
					sm={6}
					sx={gridItemStyles}
					item
				>
					<TextField
						key='IsActive'
						select
						variant={variant}
						label='Contact Status'
						value={contact.IsActive}
						name='IsActive'
						onChange={(e) => handleContactChange(e, contact._id)}
						sx={textFieldStyles}
						style={{ visibility: Object.hasOwn(contact, 'Saved') ? 'hidden' : 'visible' }}
					>
						<MenuItem
							key={'IsActive'}
							value={true}
						>
							Active
						</MenuItem>
						<MenuItem
							key={'InActive'}
							value={false}
						>
							InActive
						</MenuItem>
					</TextField>
					<TextField
						key='Salutation'
						variant={variant}
						label='Salutation'
						value={contact.Salutation}
						name='Salutation'
						onChange={(e) => handleContactChange(e, contact._id)}
						sx={textFieldStyles}
					/>
					<TextField
						key='FirstName'
						required
						variant={variant}
						label='FirstName'
						value={contact.FirstName}
						name='FirstName'
						onChange={(e) => handleContactChange(e, contact._id)}
						sx={textFieldStyles}
					/>
					<TextField
						key='LastName'
						required
						variant={variant}
						label='LastName'
						value={contact.LastName}
						name='LastName'
						onChange={(e) => handleContactChange(e, contact._id)}
						sx={textFieldStyles}
					/>
					<TextField
						key='Title'
						required
						variant={variant}
						label='Title'
						value={contact.Title}
						name='Title'
						onChange={(e) => handleContactChange(e, contact._id)}
						sx={textFieldStyles}
					/>
					<TextField
						key='Territory'
						variant={variant}
						label='Territory'
						value={contact.Territory}
						name='Territory'
						onChange={(e) => handleContactChange(e, contact._id)}
						sx={textFieldStyles}
					/>
				</Grid>
				<Grid
					sm={6}
					sx={gridItemStyles}
					item
				>
					<TextField
						key='Owner'
						select
						required
						variant={variant}
						label='Owner'
						value={contact.Owner}
						name='Owner'
						onChange={(e) => handleContactChange(e, contact._id)}
						color='secondary'
						sx={textFieldStyles}
					>
						{users &&
							users.map((user) => (
								<MenuItem
									key={user._id}
									value={user._id}
									disabled={!user.IsActive}
									sx={{
										display: `${user.IsActive || contact.Owner === user._id ? 'block' : 'none'}`,
									}}
								>{`${user.FirstName} ${user.LastName}`}</MenuItem>
							))}
					</TextField>
					<FormControl
						variant={variant}
						sx={textFieldStyles}
					>
						<InputLabel>Department</InputLabel>
						<Select
							name='Department'
							multiple
							variant={variant}
							value={contact.Department}
							onChange={(e) => handleDepartmentChange(e, contact._id)}
							input={variant === 'outlined' ? <OutlinedInput label='Department'>Department</OutlinedInput> : <Input label='Department'>Department</Input>}
							renderValue={(selected) => selected.join(', ')}
							MenuProps={{
								PaperProps: {
									style: {
										width: '20%',
										maxWidth: '20%',
									},
								},
							}}
							//sx={textFieldStyles}
						>
							{departments &&
								departments.map((department) => (
									<MenuItem
										key={department._id}
										value={department.Name}
									>
										<Checkbox checked={contact.Department.indexOf(department.Name) > -1} />
										<ListItemText primary={department.Name} />
									</MenuItem>
								))}
						</Select>
					</FormControl>
					<TextField
						key='Email'
						required
						variant={variant}
						type='email'
						label='Email'
						value={contact.Email}
						name='Email'
						onChange={(e) => handleContactChange(e, contact._id)}
						sx={textFieldStyles}
					/>
					<Grid
						container
						sx={textFieldStyles}
						style={{ display: 'flex', alignItems: 'center', alignContent: 'center', marginTop: 0, marginBottom: 0 }}
					>
						<Grid
							item
							sm={11}
							sx={gridItemStyles}
						>
							{required ? (
								<TextField
									required={required}
									variant={variant}
									label=''
									value={''}
									id='ContactNumber'
									name='ContactNumber'
									placeholder='Contact Numbers:'
									//InputProps={{ disableUnderline: true }}
									onClick={handleCreateContactNumberModal}
									onKeyDown={handleCreateContactNumberModal}
									onInvalid={(e) => e.target.setCustomValidity('Please Add At Least One Contact Number')}
									sx={{
										left: 1,
										width: textFieldStyles.minWidth,
										marginTop: textFieldStyles.marginTop,
										marginBottom: textFieldStyles.marginBottom,
										color: textFieldStyles.color,
										caretColor: 'transparent',
										cursor: 'default',
										input: {
											padding: 2.1,
											cursor: 'pointer',
											'&::placeholder': {
												color: textFieldStyles.color,
												opacity: 1,
											},
										},
									}}
								/>
							) : (
								<Typography
									onClick={handleCreateContactNumberModal}
									onKeyDown={handleCreateContactNumberModal}
									sx={{
										position: 'relative',
										border: `1px solid rgba(0, 0, 0, 0.25)`,
										borderRadius: 1,
										padding: 2,
										backgroundColor: `${textFieldStyles['& .MuiInput-input'].backgroundColor}`,
										margin: '0 auto',
										marginTop: textFieldStyles.marginTop,
										marginBottom: textFieldStyles.marginBottom,
										width: textFieldStyles.minWidth,
										textAlign: 'left',
										left: 4,
									}}
								>
									Contact Numbers: *
								</Typography>
							)}
						</Grid>
						<Grid
							item
							sm={1}
							sx={gridItemStyles}
						>
							<Tooltip title='Add Contact Number'>
								<Button
									startIcon={<AddCircle />}
									onClick={handleCreateContactNumberModal}
									sx={{ color: blueGrey[900], display: 'relative', left: '-6%' }}
								/>
							</Tooltip>
						</Grid>
						<Grid
							item
							sm={12}
							sx={gridItemStyles}
						>
							{contact.ContactNumbers && !contact.ContactNumbers.some((contactNumber) => !Object.hasOwn(contactNumber, 'ToDelete')) && (
								<Typography
									sx={{
										textAlign: 'center',
										width: '95%',
										marginTop: 2,
										fontSize: 16,
										color: orange[700],
									}}
								>
									Please Add At Least One Contact Number
								</Typography>
							)}
						</Grid>
					</Grid>
					{contact.ContactNumbers &&
						contact.ContactNumbers.map(
							(contactNumber) =>
								!Object.hasOwn(contactNumber, 'ToDelete') && (
									<Grid
										container
										key={contactNumber._id}
										sx={gridStyles}
										style={{ display: 'flex', alignItems: 'center', alignContent: 'left' }}
									>
										<Grid
											item
											xs={11}
											sx={gridItemStyles}
										>
											{contactNumberTypes && contactNumberTypes.find((x) => x._id === contactNumber.Type._id).ValidNumber ? (
												<ContactNumberPhoneInput
													key={contactNumber._id}
													disabled={false}
													variant={variant}
													textFieldStyles={textFieldStyles}
													contact={contact}
													contactNumber={contactNumber}
													handleContactNumberChange={handleContactNumberChange}
												/>
											) : (
												<ContactNumberInput
													key={contactNumber._id}
													disabled={false}
													variant={variant}
													textFieldStyles={textFieldStyles}
													contact={contact}
													contactNumber={contactNumber}
													handleContactNumberChange={handleContactNumberChange}
												/>
											)}
										</Grid>
										<Grid
											item
											xs={1}
											sx={{
												display: 'flex',
												alignItems: 'end',
												alignContent: 'center',
											}}
										>
											<Tooltip title='Delete Contact Number'>
												<span>
													<Button
														startIcon={<DeleteForever />}
														//disabled={contact.ContactNumbers.length <= 1}
														onClick={() => handleDeleteContactNumberModal(contactNumber)}
														sx={{ color: blueGrey[900], position: 'relative', left: '-6%' }}
													/>
												</span>
											</Tooltip>
										</Grid>
									</Grid>
								)
						)}
					{contact.ContactNumbers.filter((contactNumber) => Object.hasOwn(contactNumber, 'ToDelete')).length > 0 && (
						<Grid
							container
							sx={gridStyles}
						>
							<Grid
								item
								sm={12}
								sx={gridItemStyles}
							>
								<Typography
									sx={{
										textAlign: 'left',
										width: '95%',
										marginLeft: '11%',
										fontSize: 16,
										color: red[800],
										marginTop: 3,
										//marginBottom: 3,
									}}
								>
									Contact Numbers To Be Deleted:
								</Typography>
							</Grid>
						</Grid>
					)}
					{contact.ContactNumbers &&
						contact.ContactNumbers.map(
							(contactNumber) =>
								Object.hasOwn(contactNumber, 'ToDelete') && (
									<Grid
										key={contactNumber._id}
										container
										sx={gridStyles}
										style={{ display: 'flex', alignItems: 'center', alignContent: 'left' }}
									>
										<Grid
											item
											xs={11}
											sx={gridItemStyles}
										>
											{contactNumberTypes && contactNumberTypes.find((x) => x.Name === contactNumber.Type.Name).ValidNumber ? (
												<ContactNumberPhoneInput
													disabled={true}
													contact={contact}
													variant={variant}
													textFieldStyles={textFieldStyles}
													contactNumber={contactNumber}
													handleContactNumberChange={handleContactNumberChange}
												/>
											) : (
												<ContactNumberInput
													disabled={true}
													contact={contact}
													variant={variant}
													textFieldStyles={textFieldStyles}
													contactNumber={contactNumber}
													handleContactNumberChange={handleContactNumberChange}
												/>
											)}
										</Grid>
										<Grid
											item
											xs={1}
										>
											<Tooltip title='Cancel Delete'>
												<span>
													<Button
														startIcon={<Cancel />}
														onClick={() => handleCancelDelete(contactNumber)}
														sx={{ color: blueGrey[900] }}
													/>
												</span>
											</Tooltip>
										</Grid>
									</Grid>
									// <Grid
									// 	container
									// 	key={contactNumber._id}
									// 	sx={gridStyles}
									//
									// >

									// </Grid>
								)
						)}
				</Grid>
			</Grid>
			<Divider />
			<Grid
				container
				sx={gridStyles}
			>
				<Grid
					sm={6}
					sx={gridItemStyles}
					item
				>
					<TextField
						key='Address1'
						variant={variant}
						label='Address1'
						value={contact.Address1}
						name='Address1'
						onChange={(e) => handleContactChange(e, contact._id)}
						sx={textFieldStyles}
					/>
					<TextField
						key='Address2'
						variant={variant}
						label='Address2'
						value={contact.Address2}
						name='Address2'
						onChange={(e) => handleContactChange(e, contact._id)}
						sx={textFieldStyles}
					/>
					<TextField
						key='City'
						variant={variant}
						label='City'
						value={contact.City}
						name='City'
						onChange={(e) => handleContactChange(e, contact._id)}
						sx={textFieldStyles}
					/>
					<TextField
						key='State'
						variant={variant}
						label='State'
						value={contact.State}
						name='State'
						onChange={(e) => handleContactChange(e, contact._id)}
						sx={textFieldStyles}
					/>
					<TextField
						key='PostalCode'
						variant={variant}
						label='PostalCode'
						value={contact.PostalCode}
						name='PostalCode'
						onChange={(e) => handleContactChange(e, contact._id)}
						sx={textFieldStyles}
					/>
					<TextField
						key='Country'
						variant={variant}
						label='Country'
						value={contact.Country}
						name='Country'
						onChange={(e) => handleContactChange(e, contact._id)}
						sx={textFieldStyles}
					/>
				</Grid>
				<Grid
					sm={6}
					sx={gridItemStyles}
					item
				>
					<TextField
						key='Notes'
						variant='outlined'
						label='Notes'
						value={contact.Notes}
						name='Notes'
						multiline
						rows={20.1}
						onChange={(e) => handleContactChange(e, contact._id)}
						sx={textFieldStyles}
					/>
				</Grid>
			</Grid>

			{/* Created/Modified */}
			{contact && contact.CreatedBy && contact.CreatedDate && contact.LastModifiedBy && contact.LastModifiedDate && (
				<Box
					sx={{
						backgroundColor: blueGrey[100],
						width: '95%',
						margin: '0 auto',
						borderRadius: 2,
						color: blueGrey[900],
					}}
				>
					<CreatedModified account={contact} />
				</Box>
			)}

			{/* Create ContactNumber Modal */}
			<CreateContactNumberModal
				createContactNumberModal={createContactNumberModal}
				setCreateContactNumberModal={setCreateContactNumberModal}
				addNewContactNumber={addNewContactNumber}
				newContactNumber={newContactNumber}
				setNewContactNumber={setNewContactNumber}
				contactNumberTypes={contactNumberTypes}
				handleCreateContactNumberModal={handleCreateContactNumberModal}
			/>

			{/* Delete ContactNumber Modal */}
			<DeleteContactNumberModal
				deleteContactNumberModal={deleteContactNumberModal}
				setDeleteContactNumberModal={setDeleteContactNumberModal}
				deleteContactNumber={deleteContactNumber}
				confirmDeleteContactNumber={confirmDeleteContactNumber}
			/>
		</>
	);
}
