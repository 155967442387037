import React from 'react';
import { Box, Button } from '@mui/material';
import { blueGrey, orange } from '@mui/material/colors';
import ContactForm from '../contact/ContactForm';

export default function Contact({ id, newAccount, user, users, handleNext, handleBack }) {
	function submit(e) {
		e.preventDefault();

		handleNext();
	}

	return (
		<Box
			component={'form'}
			onSubmit={submit}
			sx={{ color: 'inherit', margin: 0, marginTop: 0 }}
		>
			<ContactForm
				originalAccount={newAccount}
				editingAccount={newAccount}
				contact={newAccount.Contacts[0]}
				textFieldStyles={textFieldStyles}
				variant={'outlined'}
			/>
			<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
				<Button
					//disabled={activeStep === 0}
					onClick={handleBack}
					sx={{
						mr: 1,
						color: blueGrey[50],
						backgroundColor: orange[800],
						'&:hover': {
							backgroundColor: orange[900],
						},
						//display: `${activeStep === 0 ? 'none' : 'block'}`,
					}}
				>
					Back
				</Button>
				<Box sx={{ flex: '1 1 auto' }} />
				<Button
					type='submit'
					sx={{
						color: blueGrey[50],
						backgroundColor: orange[800],
						'&:hover': {
							backgroundColor: orange[900],
						},
					}}
				>
					Next
				</Button>
			</Box>
		</Box>
	);
}

const textFieldStyles = {
	minWidth: '95%',
	textAlign: 'left',
	color: blueGrey[50],
	marginTop: 2,
	marginBottom: 2,
	borderRadius: 8,

	'& .MuiOutlinedInput-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
	},
	'& .MuiInput-input': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
	},
	'& .MuiInputLabel-root': {
		color: blueGrey[50],
	},
	'& .MuiSelect-iconOutlined': {
		color: blueGrey[50],
	},
	'& .MuiInput-root:after': {
		borderColor: blueGrey[50],
	},
	'& .MuiOutlinedInput-input .Mui-disabled': {
		color: `${blueGrey[50]} !important`,
		WebkitTextFillColor: `${blueGrey[50]} !important`,
	},
	'& .MuiInputBase-root.Mui-focused': {
		border: `4px solid ${orange[500]}`,
	},
	'& .Mui-disabled': {
		color: `${blueGrey[50]} !important`,
		WebkitTextFillColor: `${blueGrey[50]} !important`,
	},
	'& .MuiOutlinedInput-input.Mui-disabled': {
		color: `${blueGrey[50]} !important`,
		WebkitTextFillColor: `${blueGrey[50]} !important`,
	},
	'& .MuiInputLabel-shrink': {
		top: -6,
		left: -6,
		borderTopLeftRadius: 4,
		borderTopRightRadius: 4,
		border: `1px solid ${blueGrey[900]}`,
		borderBottom: 'none',
	},
	'& .MuiInputLabel-shrink.Mui-focused': {
		top: -9,
		left: -6,
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
		border: `4px solid ${orange[500]}`,
		borderBottom: `4px solid ${blueGrey[700]}`,
		color: orange[500],
	},
	'& .MuiInputLabel-outlined': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPaper-root.MuiPickersPopper-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
};
