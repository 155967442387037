import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import { blueGrey, green, orange, red } from '@mui/material/colors';
import _ from 'lodash';
import React from 'react';
import ContactForm from '../../../contact/ContactForm';
import { gridItemStyles, gridStyles } from '../../../ComponentStyles';
import { Save } from '@mui/icons-material';

export default function ContactAccordion({ originalAccount, editingAccount, contact, index, submit, saving, expanded, handleAccordionOpen }) {
	return (
		<Accordion
			key={contact._id}
			expanded={expanded === contact._id}
			onChange={(e) => handleAccordionOpen(contact._id)}
			disableGutters={true}
			sx={{
				backgroundColor: blueGrey[50],
				color: contact.IsActive ? blueGrey[50] : blueGrey[50],
				padding: 0,
				boxShadow: `0px 0px 2px .5px ${blueGrey[600]}`,
			}}
		>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Grid
					container
					sx={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						alignContent: 'center',
					}}
				>
					<Grid
						sm={3}
						item
					>
						<Typography sx={accordionGridHeaderStyles(index, originalAccount, editingAccount)}>{`${contact.FirstName} ${contact.LastName}`}</Typography>
					</Grid>
					<Grid
						sm={2}
						item
					>
						<Typography sx={accordionGridHeaderStyles(index, originalAccount, editingAccount)}>{contact.Title}</Typography>
					</Grid>
					<Grid
						sm={2}
						item
					>
						<Typography sx={accordionGridHeaderStyles(index, originalAccount, editingAccount)}>{contact.Department.join(', ')}</Typography>
					</Grid>
					<Grid
						sm={3}
						item
					>
						<Typography sx={accordionGridHeaderStyles(index, originalAccount, editingAccount)}>{contact.Email}</Typography>
					</Grid>
					<Grid
						sm={2}
						item
					>
						{contact.IsActive ? (
							<Box
								sx={{
									marginLeft: 8,
									width: '60%',
									height: 25,
									textAlign: 'center',
									backgroundColor: green[500],
								}}
							>
								Active
							</Box>
						) : (
							<Box
								sx={{
									marginLeft: 8,
									width: '60%',
									height: 25,
									textAlign: 'center',
									backgroundColor: red[500],
								}}
							>
								Inactive
							</Box>
						)}
					</Grid>
				</Grid>
			</AccordionSummary>
			{expanded === contact._id && (
				<AccordionDetails>
					<Box
						component='form'
						onSubmit={(e) => submit(e, contact)}
						sx={{
							margin: 0,
							marginTop: 0,
						}}
					>
						<Grid
							container
							sx={gridStyles}
						>
							<Grid
								sm={10}
								sx={gridItemStyles}
								item
							></Grid>
							<Grid
								sm={2}
								sx={gridItemStyles}
								item
							>
								{saving ? (
									<CircularProgress color='warning' />
								) : (
									<IconButton
										type='submit'
										style={{ border: 'none' }}
									>
										<Save
											style={{
												color: `${_.isEqual(originalAccount.Contacts[index], editingAccount.Contacts[index]) ? blueGrey[600] : orange[800]}`,
												fontSize: '1.5em',
												cursor: 'pointer',
											}}
										/>
									</IconButton>
								)}
							</Grid>
						</Grid>
						<ContactForm
							originalAccount={originalAccount}
							editingAccount={editingAccount}
							contact={contact}
							submit={submit}
							saving={saving}
							index={index}
							textFieldStyles={textFieldStyles}
							variant={'outlined'}
						/>
					</Box>
				</AccordionDetails>
			)}
		</Accordion>
	);
}

const accordionGridHeaderStyles = (index, originalAccount, editingAccount) => {
	return {
		fontWeight: 'bold',
		textAlign: 'center',
		color: `${_.isEqual(originalAccount.Contacts[index], editingAccount.Contacts[index]) ? blueGrey[800] : orange[800]}`,
	};
};

const textFieldStyles = {
	minWidth: '80%',
	textAlign: 'left',
	color: blueGrey[900],
	marginTop: '1em',
	marginBottom: '1em',
	'& .MuiInput-input': {
		color: blueGrey[900],
		backgroundColor: blueGrey[50],
	},
	'& .MuiInput-root': {
		color: blueGrey[900],
	},
	'& .MuiInputLabel-root': {
		color: blueGrey[900],
	},
	'& .MuiInput-root:after': {
		borderColor: blueGrey[900],
	},
};
