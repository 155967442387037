import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resetAccounts } from '../../redux/accountSlice';
import { resetAlerts } from '../../redux/alertsSlice';
import { resetDrawer } from '../../redux/drawerSlice';
import { resetDepartments } from '../../redux/departmentSlice';
import {
	selectIsAuthenticated,
	selectUser,
	logoutAsync,
	logout,
} from '../../redux/authSlice';
import Toolbar from '@mui/material/Toolbar';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MuiAppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import LogoutIcon from '@mui/icons-material/Logout';
import { blueGrey } from '@mui/material/colors';
import AppBarMenuItems from './AppBarMenuItems/AppBarMenuItems';
import { resetUsers } from '../../redux/userSlice';
import { resetCurrencies } from '../../redux/currencySlice';
import { resetScreeners } from '../../redux/screenerSlice';
import { resetSales } from '../../redux/salesSlice';
import { resetPrograms } from '../../redux/programSlice';
import { resetDefinitions } from '../../redux/definitionSlice';
import { resetFormats } from '../../redux/formatSlice';
import { resetGenres } from '../../redux/genreSlice';
import { resetLanguages } from '../../redux/languageSlice';
import { resetLicensedMedias } from '../../redux/licensedmediaSlice';
import { Menu, MenuItem } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PasswordSharpIcon from '@mui/icons-material/PasswordSharp';
import PasswordChangeModal from './AppBarMenuItems/PasswordChangeModal';
import { resetTasks } from '../../redux/taskSlice';
import { resetReports } from '../../redux/reportSlice';
import packageJson from '../../../package.json';
import { resetContactNumberTypes } from '../../redux/contactNumberTypeSlice';
import { resetRoles } from '../../redux/roleSlice';
import { resetTerritories } from '../../redux/territorySlice';

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open, drawerwidth }) => ({
	transition: theme.transitions.create(['margin', 'width'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		width: `calc(100% - ${drawerwidth}px)`,
		marginLeft: `${drawerwidth}px`,
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

export default function AppBarMenu({ drawerwidth, handleDrawerOpen, open }) {
	const [anchorEl, setAnchorEl] = useState(null);
	const [userMenuOpen, setUserMenuOpen] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	let isAuthenticated = useSelector(selectIsAuthenticated);
	let user = useSelector(selectUser);
	let dispatch = useDispatch();
	const navigate = useNavigate();

	const logoutUser = () => {
		setUserMenuOpen(false);
		navigate('/login');
		dispatch(logoutAsync());
		dispatch(logout());
		dispatch(resetAlerts());
		dispatch(resetDrawer());
		//dispatch(resetTabs());
		dispatch(resetAccounts());
		dispatch(resetDepartments());
		dispatch(resetCurrencies());
		dispatch(resetCurrencies());
		dispatch(resetUsers());
		dispatch(resetScreeners());
		dispatch(resetSales());
		dispatch(resetPrograms());
		dispatch(resetDefinitions());
		dispatch(resetFormats());
		dispatch(resetGenres());
		dispatch(resetLanguages());
		dispatch(resetLicensedMedias());
		dispatch(resetTasks());
		dispatch(resetReports());
		dispatch(resetContactNumberTypes());
		dispatch(resetRoles());
		dispatch(resetReports());
		dispatch(resetTerritories());
	};

	useEffect(() => {
		if (!isAuthenticated) {
			navigate('/login');
		}
	}, [isAuthenticated, navigate]);

	const handleMenu = (event) => {
		setUserMenuOpen(true);
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setUserMenuOpen(false);
		setAnchorEl(null);
	};

	return (
		<AppBar
			position='fixed'
			open={open}
			drawerwidth={drawerwidth}
			elevation={0}
			sx={{
				backgroundColor: blueGrey[900],
				'& .MuiPaper-root-MuiAppBar-root': {
					boxShadow: 'none',
				},
				'& .MuiPaper-root': {
					boxShadow: 'none',
				},
				'& .MuiAppBar-root': {
					boxShadow: 'none',
				},
			}}
		>
			<Toolbar
				sx={{
					minHeight: '48px !important',
					marginTop: 0,
					marginBottom: 0,
					padding: 0,
				}}
			>
				<IconButton
					color='inherit'
					aria-label='open drawer'
					onClick={isAuthenticated ? handleDrawerOpen : null}
					edge='start'
					sx={{
						marginRight: 2,
						marginTop: '13px',
						minHeight: '35px',
						maxHeight: '35px',
						...(open && { display: 'none' }),
					}}
				>
					<MenuIcon />
				</IconButton>
				<Typography
					variant='h6'
					noWrap
					component='div'
					sx={{
						flexGrow: 0,
						marginRight: '2em',
						color: 'red',
						marginTop: '13px',
						minHeight: '35px',
						maxHeight: '35px',
					}}
				>
					<Link
						to='/'
						component={RouterLink}
						sx={{
							textDecoration: 'none',
							color: 'red',
						}}
					>
						LGI Media
					</Link>
				</Typography>
				<div style={{ display: 'flex', flexGrow: 10 }}>
					{isAuthenticated && (
						<>
							<AppBarMenuItems category={'Sales'} />
							<AppBarMenuItems category={'Acquisitions'} />
							<AppBarMenuItems category={'Support'} />
							<AppBarMenuItems category={'Finance'} />
							{user && user.Role === 'admin' && (
								<>
									<AppBarMenuItems category={'Admin'} />
									<AppBarMenuItems category={'Reports'} />
								</>
							)}
						</>
					)}
				</div>
				{isAuthenticated && (
					<div>
						<IconButton
							onClick={handleMenu}
							sx={{ p: 0 }}
						>
							<AccountCircleIcon
								sx={{ backgroundColor: blueGrey[900], color: blueGrey[50] }}
							/>
							{/* <Avatar
									alt='Remy Sharp'
									src='http://www.gravatar.com/avatar/3b3be63a4c2a439b013787725dfce802?d=identicon'
								/> */}
						</IconButton>
						<Menu
							anchorEl={anchorEl}
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							open={userMenuOpen}
							onClose={handleClose}
							MenuListProps={{
								'aria-labelledby': 'basic-button',
								onMouseLeave: handleClose,
							}}
							sx={{
								top: 32,
								color: blueGrey[50],
								'& .MuiList-root': {
									backgroundColor: blueGrey[800],
								},
								'& .MuiPaper-root': {
									borderTopLeftRadius: 0,
									borderTopRightRadius: 0,
									boxShadow: '0px 3px 3px black',
									backgroundColor: blueGrey[800],
								},
							}}
						>
							<MenuItem
								sx={{
									minHeight: '35px',
									//marginTop: '13px',
									maxHeight: '35px',
									color: blueGrey[50],
								}}
							>
								Version: {packageJson.version}
							</MenuItem>
							<MenuItem
								sx={{
									minHeight: '35px',
									//marginTop: '13px',
									maxHeight: '35px',
									color: blueGrey[50],
								}}
							>
								<IconButton
									onClick={() => setModalOpen(true)}
									color='inherit'
									sx={{
										//marginTop: '13px',
										minHeight: '35px',
										maxHeight: '35px',
										color: blueGrey[50],
									}}
								>
									<PasswordSharpIcon
										user={user}
										sx={{ mr: 2 }}
									/>
									<Typography sx={{ fontSize: 16 }}>Change Password</Typography>
								</IconButton>
							</MenuItem>
							<MenuItem>
								<IconButton
									onClick={logoutUser}
									color='inherit'
									sx={{
										minHeight: '35px',
										maxHeight: '35px',
										color: blueGrey[50],
									}}
								>
									<LogoutIcon
										user={user}
										sx={{ mr: 2 }}
									/>
									<Typography sx={{ fontSize: 16 }}>Logout</Typography>
								</IconButton>
							</MenuItem>
						</Menu>
					</div>
				)}
			</Toolbar>
			<PasswordChangeModal
				modalOpen={modalOpen}
				setModalOpen={setModalOpen}
			/>
		</AppBar>
	);
}
