import { createSlice } from '@reduxjs/toolkit';
import api from '../utils/api';
import { createAlertAsync, handleErrorAlertAsync } from './alertsSlice';
import { verifyAuthAsync } from './authSlice';

const initialState = {
	screenerReport: null,
	contactReport: null,
};

export const reportSlice = createSlice({
	name: 'report',
	initialState: initialState,
	reducers: {
		getContactReport: (state, action) => {
			state.contactReport = action.payload;
		},
		getScreenerReport: (state, action) => {
			state.screenerReport = action.payload;
		},
		resetReports: () => initialState,
	},
});

export const { getContactReport, getScreenerReport, resetReports } = reportSlice.actions;

// Selectors
export const selectScreenerReport = (state) => state.reports.screenerReport;
export const selectContactReport = (state) => state.reports.contactReport;

// Get Contact Report Async
export const getContactReportAsync = (formData) => async (dispatch) => {
	await api
		.get(`/reports/contactReport`, { withCredentials: true })
		.then((res) => {
			dispatch(getContactReport(res.data));
		})
		.catch((error) => {
			//console.log(error);
			dispatch(handleErrorAlertAsync(error));
			verifyAuthAsync(error.response.data.message);
		});
};

// Get Screener Report Async
export const getScreenrReportAsync = (formData) => async (dispatch) => {
	await api
		.get(`/reports/screenerReport?StartDate=${formData.StartDate}&EndDate=${formData.EndDate}`, { withCredentials: true })
		.then((res) => {
			dispatch(getScreenerReport(res.data));
		})
		.catch((error) => {
			//console.log(error);
			dispatch(handleErrorAlertAsync(error));
			verifyAuthAsync(error.response.data.message);
		});
};

export default reportSlice.reducer;
