import React from 'react';
import { MuiTelInput } from 'mui-tel-input';
import { isPossiblePhoneNumber, isValidPhoneNumber, validatePhoneNumberLength } from 'libphonenumber-js';
export default function ContactNumberPhoneInput({ contactNumber, handleContactNumberChange, contact, disabled, variant, textFieldStyles }) {
	return (
		<MuiTelInput
			//disabled={disabled}
			required
			key={contactNumber._id}
			type='phone'
			variant={variant}
			label={contactNumber.Type.Name}
			value={contactNumber.Number}
			name={contactNumber._id}
			onChange={(e) => handleContactNumberChange(e, contact._id, contactNumber._id, contactNumber.Type)}
			forceCallingCode={true}
			error={!isValidPhoneNumber(contactNumber.Number) && !isPossiblePhoneNumber(contactNumber.Number) && validatePhoneNumberLength(contactNumber.Number)}
			sx={textFieldStyles}
			style={{
				marginTop: 0,
				marginBottom: 0,
				left: 4,
			}}
		/>
	);
}
